// @flow
import * as React from 'react'
import { CTAPrimary, CTAPrimaryLink, BurgerButton } from './shared/buttons'
import styled from 'styled-components'
import {
  Container,
  Row,
  Col,
  config,
  Visible,
  ThemedStyledProps
} from 'react-awesome-styled-grid'
import type { AppStateType } from '../reducers/appstate'
import type { Dispatch } from 'redux'
import { useRouter, withRouter } from 'next/router'
import { connect } from 'react-redux'
import {
  openSubscribe,
  openOurStory,
  openBlog,
  openGift,
  openHome,
  signIn, LinkWithUtms
} from '../actions/navigationActions'
import { signOut } from '../actions/userActions'
import { DipseaLogo } from './shared/icons'
import { colors } from '../components/shared/colors'
import { togglePromoModal } from '../actions/promoActions'
import DownButtonCta from '@components/DownloadButton'
import FadeInContent from '@components/shared/FadeInContent'
import BackgroundGradient from '@components/BackgroundGradient'
import { useSearchParams } from 'next/navigation'

type PropsType = {
  backgroundColor: string, // on browsers that don't support blur, we need a background color,
  isSubscribed: boolean,
  signedIn: boolean,
  isAssetLoaded: boolean,
  isPromoModal: boolean,
  isOpen?: boolean,
  isDiscover?: boolean,
  isBlog?: boolean
}

type DispatchPropsType = {
  openSubscribe: (promo?: string) => void,
  openOurStory: () => void,
  openBlog: () => void,
  openGift: () => void,
  openHome: () => void,
  signOut: () => void,
  signIn: () => void,
  togglePromoModal: () => void
}

export function TopNavMobile (props: PropsType & DispatchPropsType): React.Node {
  const [isMobileNavOpen, setIsMobileNavOpen] = React.useState(props.isOpen || null)
  const navRef = React.useRef(null)
  const router = useRouter()
  const searchParams = useSearchParams()
  const [isSimpleNav, setIsSimpleNav] = React.useState<boolean>(false)
  // if the modal is open prevent the main body from scrolling
  React.useEffect((): void => {
    const body = document.querySelector('body')
    if (body && body.style) {
      body.style.overflow = isMobileNavOpen ? 'hidden' : 'auto'
    }
  }, [isMobileNavOpen])

  const [loadAnimation, setLoadAnimation] = React.useState<boolean>(false)
  const [loadedAnimation, setLoadedAnimation] = React.useState<boolean>(false)

  React.useEffect((): void => {
    if (!loadAnimation) {
      setTimeout((): void => setLoadAnimation(true), 500)
      setTimeout((): void => setLoadedAnimation(true), 1500)
    }
  }, [props.isAssetLoaded])

  React.useEffect((): void => {
    if (!loadAnimation && props.isAssetLoaded) {
      setTimeout((): void => setLoadAnimation(true), 500)
    }
  }, [props.isAssetLoaded])

  React.useEffect((): void => {
    const n = searchParams?.get('n')
    if (!!n && n === 's') {
      setIsSimpleNav(true)
    }
  }, [searchParams])

  // if the mobile nav is open, on the entire nav, querySelectorAll clickable items. Set focus on first
  React.useEffect((): any => {
    if (isMobileNavOpen) {
      const possibleElements = (navRef.current || {}).querySelectorAll(
        'a[href], button, textarea, input, select, [role="button"]'
      )
      let firstFocus =
        possibleElements.length >= 0 ? possibleElements[0] : null
      if (possibleElements.length >= 0) {
        const firstFocusableElement = possibleElements[0]
        if (
          firstFocusableElement &&
          !firstFocusableElement.className.includes('burger')
        ) {
          firstFocus = possibleElements[1] || null
        }
      }
      if (firstFocus) {
        firstFocus.focus()
      }
      document.addEventListener('keydown', keyListener)
      return (): any => document.removeEventListener('keydown', keyListener)
    }
  }, [isMobileNavOpen])

  const keyListener = (e: KeyboardEvent): any => {
    const listener: ((e: KeyboardEvent) => any) | null =
      keyListenersMap.get(e.keyCode) || null
    if (listener) {
      return listener(e)
    }
  }

  const handleTabKey = (e: KeyboardEvent): any => {
    const focusableModalElements = (navRef.current || {}).querySelectorAll(
      'a[href], button, textarea, input, select, [role="button"]'
    )
    const firstElement = focusableModalElements[0]
    const lastElement =
      focusableModalElements[focusableModalElements.length - 1]

    if (!e.shiftKey && document.activeElement === lastElement) {
      firstElement.focus()
      return e.preventDefault()
    } else if (e.shiftKey && document.activeElement === firstElement) {
      lastElement.focus()
      e.preventDefault()
    }
  }

  const toggleMobileNav = (): void => {
    setIsMobileNavOpen(!isMobileNavOpen)
  }

  const keyListenersMap = new Map([
    [27, toggleMobileNav], // Esc
    [9, handleTabKey] // Tab
  ])
  if (props.isDiscover || isSimpleNav) {
    return null
  }
  return (
    <Visible xs={true} sm={true} md={true} lg={false} xl={false}>
      <Nav ref={navRef} load={loadAnimation}>
        <NavContainer
          backupBackgroundColor={props.backgroundColor}
          isMobileNavOpen={isMobileNavOpen}
          load={loadAnimation}
          loadedAnimation={loadedAnimation}
        >
          <Container>
            <Row>
              <Col xs={2} align={'left'} gutterWidth={0}>
                <MobileHeaderLeft>
                  <BurgerButton
                    onClick={toggleMobileNav}
                    isOpen={isMobileNavOpen}
                  />
                  <HomeButton
                    onClick={(): void => props.openHome()}
                    aria-label="Dipsea, opens homepage"
                  >
                    <DipseaLogo size={110} />
                  </HomeButton>
                </MobileHeaderLeft>
              </Col>

              <Col xs={2} align={'flex-end'} justify="center">
                { props.isBlog
                  ? (<LinkWithUtms pathname={'/subscribe?f=2'} passHref={true}>
                    <CTAPrimary className={'listen'}>
                      Free Trial
                    </CTAPrimary>
                  </LinkWithUtms>)
                  : (<CTAPrimaryLink
                    href={'/discover'}
                    passHref={true}
                    style={{ minWidth: 110 }}
                  >
                    BROWSE
                  </CTAPrimaryLink>)}
              </Col>
            </Row>
          </Container>
        </NavContainer>
        {isMobileNavOpen && (
          <MobileNavList
            backupBackgroundColor={props.backgroundColor}
          >
            <Container>
              <MobileNavButtonsContainer>
                <RiseUp time={0.3}>
                  <MobileNavButton
                    className="item-3"
                    onClick={(): void => {
                      toggleMobileNav()
                      props.openSubscribe()
                    }}
                  >
                    Free Trial
                  </MobileNavButton>

                </RiseUp>

                <RiseUp time={0.35}>
                  <MobileNavButton
                    className="item-2"
                    onClick={(): void => {
                      toggleMobileNav()
                      router.push('/discover')
                    }}
                  >
                    Browse
                  </MobileNavButton>
                </RiseUp>

                <RiseUp time={0.4}>
                  <MobileNavButton
                    className="item-2"
                    onClick={(): void => {
                      toggleMobileNav()
                      props.openBlog()
                    }}
                  >
                    Blog
                  </MobileNavButton>
                </RiseUp>

                <RiseUp time={0.45}>
                  <MobileNavButton
                    className="item-1"
                    onClick={(): void => {
                      toggleMobileNav()
                      props.openOurStory()
                    }}
                  >
                    About Us
                  </MobileNavButton>
                </RiseUp>

                <RiseUp time={0.5}>
                  <Divider className="item-5" />
                </RiseUp>

                <RiseUp time={0.55}>
                  <NavButton
                    className="item-6"
                    isMobile
                    onClick={(): void =>
                      props.signedIn ? props.signOut() : props.signIn()
                    }
                  >
                    {props.signedIn ? 'Sign Out' : 'Sign In'}
                  </NavButton>
                </RiseUp>
              </MobileNavButtonsContainer>
            </Container>
            <UnlockOrDriveContainer>
              <FadeInContent fadeInTime={1}>
              <UnlockOrDrive>
                <ImageContainer>
                <BackgroundGradient quadrant={1} overlayColor='magenta' />
                </ImageContainer>
                {props.isSubscribed && <DriveContainer>
                  <DriveHeader>
                    It's even better<br/> on the app.
                  </DriveHeader>
                  <DriveCtaContainer>
                    <DownButtonCta/>
                  </DriveCtaContainer>
                </DriveContainer>}
                {!props.isSubscribed && <DriveContainer>
                  <DriveHeader>
                    Unlock 1,000+ spicy audiobooks and more.
                  </DriveHeader>
                  <DriveCtaContainer>
                    <CTAPrimary className='subscribe-cta' onClick={props.openSubscribe}>Try For Free</CTAPrimary>
                  </DriveCtaContainer>
                </DriveContainer>}
              </UnlockOrDrive>
              </FadeInContent>
            </UnlockOrDriveContainer>
          </MobileNavList>
        )}
      </Nav>
    </Visible>
  )
}

const ImageContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 0;
`
const DriveContainer = styled.div`
    z-index: 5;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: 14px;
    ${(props: any): string => config(props).media.xs`
  `}
    ${(props: any): string => config(props).media.sm`
  `}
    ${(props: any): string => config(props).media.md`
  `}
    ${(props: any): string => config(props).media.lg`
  `}
    ${(props: any): string => config(props).media.xl`
  `}
`
const DriveCtaContainer = styled.div`
  width: 90%;
  button {
    width: 100%;
  }
  ${(props: any): string => config(props).media.xs`
  `}
  ${(props: any): string => config(props).media.sm`
  `}
  ${(props: any): string => config(props).media.md`
  `}
  ${(props: any): string => config(props).media.lg`
  `}
  ${(props: any): string => config(props).media.xl`
  `}
`
const DriveHeader = styled.div`
    font-family: "Queens Condensed Light";
    font-size: 36px;
    font-style: normal;
    font-weight: 300;
    line-height: 110%;
    width: 100%;
    text-align: center;
    color: ${colors.sand};
    margin-top: 32px;
    margin-bottom: 16px;
    ${(props: any): string => config(props).media.xs`
  `}
    ${(props: any): string => config(props).media.sm`
      br {
      display: none;
      }
  `}
    ${(props: any): string => config(props).media.md`
  `}
    ${(props: any): string => config(props).media.lg`
  `}
    ${(props: any): string => config(props).media.xl`
  `}
`
const UnlockOrDrive = styled.div`
    display: flex;
    flex-direction: column;
    position: relative;
    border-radius: 20px;
    overflow: hidden;
    z-index: 1;
    margin: 20px;
    max-width: 100%;
    bottom: 20px;
    position: sticky;
    ${(props: any): string => config(props).media.xs`
  `}
    ${(props: any): string => config(props).media.sm`
  `}
    ${(props: any): string => config(props).media.md`
  `}
    ${(props: any): string => config(props).media.lg`
  `}
    ${(props: any): string => config(props).media.xl`
  `}
`
const UnlockOrDriveContainer = styled.div`
    display: flex;
    flex-direction: column;
    height: 100%;
    ${(props: any): string => config(props).media.xs`
  `}
    ${(props: any): string => config(props).media.sm`
  `}
    ${(props: any): string => config(props).media.md`
  `}
    ${(props: any): string => config(props).media.lg`
  `}
    ${(props: any): string => config(props).media.xl`
  `}
`

const UnderlineOnFocusVisible = `
  border: 1px solid transparent;
  transition: border-bottom .25s;
  :focus-visible {
    border-bottom: 1px solid ${colors.white};
  }
`
const Nav = styled.nav`
  display: flex;
  position: -webkit-sticky;
  position: sticky;
  top: 0px;
  width: 100%;
  height: 60px;
  z-index: 10;
`
const NavContainer = styled.div`
  align-items: center;
  display: flex;
  width: 100%;
  height: 60px;
  z-index: 1;
  opacity: ${(props: any): number => (props.load ? 1 : 0)};
  ${(props: any): string => (props.loadedAnimation ? 'position: none;' : 'position: absolute;')};
  ${(props: any): string => (props.load ? 'top: 0px' : 'top: -60px')};
  transition: opacity ease-in 1s, top .8s linear;
  background: ${(props: { backupBackgroundColor: ?string }): string =>
    props.backupBackgroundColor
      ? props.backupBackgroundColor
    : colors.midnightBlue90};
  ${(props: { isMobileNavOpen: boolean }): ?string => {
    if (!props.isMobileNavOpen) {
      return `
        @supports (backdrop-filter: none) {
          background: rgba(0, 0, 0, 0.03);
          backdrop-filter: blur(80px);
        }
      `
    }
  }}
  ${(props: ThemedStyledProps): string => config(props).media.md`
    height: 75px;
  `}
`
const MobileNavList = styled.div`
  height: 100vh;
  position: absolute;
  display: flex;
  flex-direction: column;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100vw;
  padding: 96px 0 36px;
  animation: fadeIn linear .3s;
  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  background: ${(props: { backupBackgroundColor: ?string }): string =>
    props.backupBackgroundColor
      ? props.backupBackgroundColor
      : colors.midnightBlue};
`
const RiseUp = styled.div`
  padding: 0;
  margin: 0;
  animation: ${(props: { time: number }): string => `riseUp linear ${props.time}s`};
  @keyframes riseUp {
    0% {
      transform: translateY(500px);
      opacity: 0;
    }
    100% {
      transform: translateY(0);
      opacity: 1;
    }
  }
`
const MobileNavButtonsContainer = styled.div`
  align-items: flex-start;
  display: flex;
  flex-direction: column;
`
const NavButton = styled.button`
  background: none;
  color: ${colors.sand};
  cursor: pointer;
  font-family: 'Good Sans Light';
  font-size: 16px;
  height: 33px;
  outline: none;
  margin: 0;
  padding: 0;
  text-transform: uppercase;
  ${(props: ThemedStyledProps): string => config(props).media.lg`
    font-size: 11px;
    letter-spacing: 0.03em;
    margin: 0 16px 0 0;
    color: ${colors.white};
  `}
  ${(props: ThemedStyledProps): string => config(props).media.xl`
      font-size: 12px;
      margin: 0 12px;
  `}
  ${(props: { marginRight: boolean, isMobile: boolean }): ?string => {
    if (props.isMobile) {
      return `
        text-align: left;
        font-size: 18px;
        text-transform: capitalize;
      `
    } else if (props.marginRight) {
      return `margin-right: 24px;`
    }
  }}
  ${UnderlineOnFocusVisible}
`
const MobileNavButton = styled.button`
  background: none;
  color: ${colors.sand};
  cursor: pointer;
  font-family: "Queens Condensed Light";
  font-style: normal;
  font-weight: 300;
  font-size: 45px;
  line-height: 110%;
  outline: none;
  padding: 0;
  text-align: left;
  margin: 0 0 18px;
  ${(props: ThemedStyledProps): string => config(props).media.md`
    font-size: 60px;
    margin: 0 0 20px;
  `}
  ${UnderlineOnFocusVisible}
`
const HomeButton = styled.button`
  outline: none;
  padding: 0;
  background: none;
  cursor: pointer;
  margin: 10px 0 0 0;
  ${UnderlineOnFocusVisible}
`
const MobileHeaderLeft = styled.div`
  align-items: center;
  display: flex;
  min-width: 183px;
`
const Divider = styled.div`
  background-color: ${colors.sand};
  height: 1px;
  margin: 11px 0 24px;
  width: 28px;
  ${(props: any): string => config(props).media.md`
     margin: 48px 0 50px;
  `}
`

const mapStateToProps = (state: AppStateType, ownProps: any): any => {
  return {
    isSubscribed: true || state.user.isSubscribed,
    signedIn: state.user.id,
    isOpen: ownProps.isOpen || null
  }
}

const mapDispatchToProps = (dispatch: Dispatch<any>): any => {
  return ({
    openSubscribe: (promo?: string): void => dispatch(openSubscribe(promo)),
    openOurStory: (): void => dispatch(openOurStory()),
    openBlog: (): void => dispatch(openBlog()),
    openGift: (): void => dispatch(openGift()),
    openHome: (): void => dispatch(openHome()),
    signOut: (): void => dispatch(signOut()),
    signIn: (): void => dispatch(signIn()),
    togglePromoModal: (): void => dispatch(togglePromoModal())
  }: DispatchPropsType)
}

export default withRouter(
  connect<PropsType & DispatchPropsType, *, _, _, _, _>(
    mapStateToProps,
    mapDispatchToProps
  )(TopNavMobile)
)
