// @flow
import * as React from 'react'
import styled from 'styled-components'
import { colors } from '@components/shared/colors'
import { config } from 'react-awesome-styled-grid'
import { isAndroid, isMobile } from 'react-device-detect'

type PropsType = {}

export default function DownButtonCta (props: PropsType): React.Node {
  const [d2dLink, setD2dLink] = React.useState('https://dipsea.app.link/download-app-store')
  React.useEffect((): void => {
    if (isAndroid && isMobile) {
      setD2dLink('https://dipsea.app.link/download-play-store')
    }
  }, [])
  return (
    <DownButtonCtaContainer>
      <DownloadBtn
        target={'_blank'}
        className="mobile-download"
        href={d2dLink}
        rel="noopener noreferrer">
        Download Dipsea
      </DownloadBtn>
    </DownButtonCtaContainer>
  )
}

const DownloadBtn = styled.a`
  background-color: ${colors.orange};
  border: none;
  border-radius: 24px;
  color: ${colors.white};
  cursor: pointer;
  font-family: "Good Sans Bold";
  font-style: normal;
  font-weight: normal;
  letter-spacing: 0.3px;
  margin: 1px;
  outline: none;
  text-decoration: none;
  text-transform: uppercase;
  width: 100%;
  text-align: center;
  :hover,
  :focus {
    opacity: 0.8;
  }
  :disabled {
    opacity: 0.8;
    cursor: "not-allowed";
  }

  height: 40px;
  line-height: 40px;
  padding: 0 12px;
  font-size: 10px;
  
  ${(props: any): string => config(props).media.md`
    height: 40px;
    line-height: 40px;
    padding: 0 28px;
    font-size: 12px;
    &.desktop-download {
      display: block;
    }

  `}
  ${(props: any): string => config(props).media.lg`
    height: 48px;
    line-height: 48px;
    padding: 0 36px;
  `}
`
const DownButtonCtaContainer = styled.div`
    display: flex;

    ${(props: any): string => config(props).media.xs`
  `}
    ${(props: any): string => config(props).media.sm`
  `}
    ${(props: any): string => config(props).media.md`
  `}
    ${(props: any): string => config(props).media.lg`
  `}
    ${(props: any): string => config(props).media.xl`
  `}
`
